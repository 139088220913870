<template>
  <CreateUpdateTemplate
    :customClass="'purchase-order-create'"
    v-if="getPermission('purchase-order:update')"
  >
    <template v-slot:header-title>
      <h1 class="form-title d-flex">Raise Purchase Order</h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack"
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        v-on:click="updateOrCreate('')"
        color="cyan"
      >
        Save
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="purchaseOrderForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate('')"
      >
        <v-container fluid>
          <v-row>
            <v-col md="6">
              <table class="width-100">
                <tr>
                  <td colspan="2" class="font-weight-700 font-size-16 pb-2">
                    Project Details
                  </td>
                  <td colspan="2" class="font-weight-700 font-size-16 pb-2">
                    Contractor Details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Project #</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{ projectDetails.barcode }}</template>
                  </td>
                  <td class="font-size-16 py-2" width="250">Contractor #</td>
                  <td class="font-weight-700 font-size-16 pb-2" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.barcode">{{
                      contractorDetails.barcode
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Project Title</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.name">{{
                      projectDetails.name
                    }}</template>
                  </td>
                  <td class="font-size-16 py-2" width="250">Display Name</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.display_name">{{
                      contractorDetails.display_name
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Started At</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.started_at">{{
                      formatDate(projectDetails.started_at)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >Not Started Yet</em
                      ></template
                    >
                  </td>
                  <td class="font-size-16 py-2" width="250">Email</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.default_person">{{
                      contractorDetails.default_person.primary_email
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Email</em></template
                    >
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">Deadline</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="projectDetails.deadline">{{
                      formatDate(projectDetails.deadline)
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Deadline</em></template
                    >
                  </td>
                  <td class="font-size-16 py-2" width="250">Phone No.</td>
                  <td class="font-weight-700 font-size-16 pb-1" width="150">
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-if="contractorDetails.default_person">{{
                      contractorDetails.default_person.primary_phone
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Phone No.</em></template
                    >
                  </td>
                </tr>
              </table>
              <h3 class="font-weight-700 font-size-16 pb-2 mt-2">Quotation</h3>
              <!-- remove  v-on:change="getQuotationLineItems" -->
              <v-autocomplete
                dense
                filled
                v-model.number="purchaseOrderCreate.quotation"
                :items="quotationList"
                item-color="cyan"
                color="cyan"
                label="Quotation"
                solo
                flat
                return-object
                :disabled="pageLoading"
                :loading="pageLoading"
                item-value="id"
                item-text="quotation_title"
                :rules="[
                  validateRules.required(
                    purchaseOrderCreate.quotation,
                    'Quotation'
                  ),
                ]"
              >
                <template v-slot:selection="{ item }">
                  <span>
                    <span class="font-weight-700 font-size-16 mr-2"
                      >{{ item.quotation_title }} - {{ item.barcode }}</span
                    >
                    <template
                      v-if="
                        item &&
                        item.final_variation &&
                        item.final_variation == 1
                      "
                    >
                      <v-chip
                        class="white--text"
                        color="green lighten-1"
                        x-small
                      >
                        Final Summary Report
                      </v-chip>
                    </template>
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <span>
                    <span class="font-weight-700 font-size-16 mr-2"
                      >{{ item.quotation_title }} - {{ item.barcode }}</span
                    >
                    <template
                      v-if="
                        item &&
                        item.final_variation &&
                        item.final_variation == 1
                      "
                    >
                      <v-chip
                        class="white--text"
                        color="green lighten-1"
                        x-small
                      >
                        Final Summary Report
                      </v-chip>
                    </template>
                  </span>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      No Quotation(s) Found.
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <!-- <v-layout v-if="!lodash.isEmpty(purchaseOrderCreate.quotation)">
                <v-flex>
                  <label class="font-weight-700 font-size-16"
                    >Quotation Details</label
                  >
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.quotation.quotation_title }}
                  </p>
                  <p class="mb-0 font-size-16 custom-nowrap-ellipsis">
                    {{ purchaseOrderCreate.quotation.attention }}
                  </p>
                </v-flex>
              </v-layout> -->
            </v-col>
            <v-col md="5">
              <table class="width-100">
                <tr>
                  <td colspan="3" class="font-weight-700 font-size-16 pb-2">
                    Purchase Order details
                  </td>
                </tr>
                <tr>
                  <td class="font-size-16 py-2" width="250">
                    Purchase Order number
                  </td>
                  <td
                    colspan="2"
                    class="font-weight-700 font-size-16 pb-2"
                    width="150"
                  >
                    <v-skeleton-loader
                      v-if="pageLoading"
                      class="custom-skeleton"
                      type="text"
                    ></v-skeleton-loader>
                    <template v-else>{{
                      purchaseOrderCreate.barcode
                    }}</template>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Reference Number #</td>
                  <td colspan="2" class="py-2">
                    <v-text-field
                      dense
                      color="cyan"
                      filled
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-model.trim="purchaseOrderCreate.supplier_reference"
                      label="Supplier Ref #"
                      :rules="[
                        validateRules.minLength(
                          purchaseOrderCreate.supplier_reference,
                          'Supplier Ref #',
                          1
                        ),
                        validateRules.maxLength(
                          purchaseOrderCreate.supplier_reference,
                          'Supplier Ref #',
                          100
                        ),
                      ]"
                      solo
                      flat
                      hide-details
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="purchaseOrderCreate.date"
                      :placeholder="'Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setPODate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Expected Delivery Date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="
                        purchaseOrderCreate.expected_delivery_date
                      "
                      :placeholder="'Expected Delivery Date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setExpectedDate"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td class="pb-2 font-size-16">Payment Term</td>
                  <td colspan="2" class="py-2">
                    <v-select
                      dense
                      filled
                      :items="paymentTermList"
                      item-color="cyan"
                      color="cyan"
                      label="Payment Term"
                      solo
                      flat
                      hide-details
                      v-model.number="purchaseOrderCreate.payment_term"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      item-value="id"
                      item-text="text"
                      :rules="[
                        validateRules.required(
                          purchaseOrderCreate.payment_term,
                          'Payment Term'
                        ),
                      ]"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-title>
                            No Payment Term(s) Found.
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
                <tr v-if="purchaseOrderCreate.payment_term == 5">
                  <td class="pb-2 font-size-16">Due date</td>
                  <td colspan="2" class="py-2">
                    <DatePicker
                      :defaultDate.sync="purchaseOrderCreate.due_date"
                      :placeholder="'Due date'"
                      solo
                      :pageLoading.sync="pageLoading"
                      v-on:update:date-picker="setDueDate"
                    ></DatePicker>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col md="12" v-if="getPermission('line-item:create')">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text class="p-6 font-size-16">
                    <v-row dense>
                      <v-col md="12">
                        <PurchaseOrderLineItem
                          is-update-mode
                          v-if="dbLineItems.length"
                          :updateData.sync="updateLineItemCalculation"
                          isPurchaseOrder
                          :showCost="purchaseOrderCreate.show_cost"
                          :db-line-items="dbLineItems"
                          :pageLoading.sync="pageLoading"
                          v-on:load:content="getPurchaseOrder"
                        ></PurchaseOrderLineItem>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col md="12">
              <PurchaseOrderTermsConditions
                :updateData.sync="updateTermCondition"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderTermsConditions>
            </v-col>
            <v-col md="12">
              <PurchaseOrderNotesAttachment
                isPurchaseOrder
                :updateData.sync="updateNotesAttachment"
                :pageLoading.sync="pageLoading"
              ></PurchaseOrderNotesAttachment>
            </v-col>
          </v-row>
          <!-- </perfect-scrollbar> -->
        </v-container>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>
<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_ERROR,
  PUT,
  GET,
  QUERY,
} from "@/core/services/store/request.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import PurchaseOrderLineItem from "@/view/pages/partials/Line-Item.vue";
import PurchaseOrderTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import PurchaseOrderNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import {
  LineItemEventBus,
  LineItemCalculationEventBus,
} from "@/core/lib/line-item/line.item.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";
import { toNumber, filter } from "lodash";
import { currentUser } from "@/core/services/jwt.service";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "purchase-order-create",
  title: "Create Task",
  data() {
    return {
      pageLoading: false,
      purchaseOrderAction: null,
      barcodeDialog: false,
      is_admin: false,
      is_id_person: false,
      supplierList: [],
      quotationList: [],
      paymentTermList: [],
      notesAttachment: null,
      updateNotesAttachment: new Object(),
      termCondition: null,
      updateTermCondition: new Object(),
      projectDetails: new Object(),
      contractorDetails: new Object(),
      updateLineItem: new Array(),
      updateLineItemCalculation: new Object(),
      lineItem: new Array(),
      lineItemCalculation: new Object(),
      purchaseOrder: 0,
      project: 0,
      dbLineItems: [],
      contractor: 0,
      purchaseOrderId: 0,
      purchaseOrderLineItems: new Array(),
      purchaseOrderCreate: new Object({
        quotation: null,
        barcode: null,
        project: null,
        show_cost: true,
        reference: null,
        supplier_reference: null,
        date: null,
        expected_delivery_date: null,
        due_date: null,
        payment_term: 1,
        attachments: [],
      }),
    };
  },
  watch: {
    dbLineItems: {
      deep: true,
      handler(param) {
        console.log({ param });
      },
    },
  },
  components: {
    DatePicker,
    CreateUpdateTemplate,
    PurchaseOrderTermsConditions,
    PurchaseOrderNotesAttachment,
    PurchaseOrderLineItem,
  },
  methods: {
    getQuotationLineItems(quotation) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "all-line-item",
          data: { quotation: quotation.id },
        })
        .then(({ data }) => {
          let lineItemdata = _this.lodash.map(data, function (row) {
            return {
              id: null,
              group_name: null,
              package_id: null,
              group_me: null,
              group_active: false,
              group_primary: false,
              type: 4,
              product: row && row.product ? row.product.name : "",
              product_id: row.product.product,
              description: row.description,
              quantity: row.quantity,
              quantity_type: null,
              tax: row.tax,
              status: true,
              taxObject: null,
              discount: row.discount,
              rate: row.rate,
              total: row.total,
              product_type: row.product.product_type,
            };
          });
          this.lineItem = lineItemdata;
          this.allcheckbox = true;
        })
        .catch((error) => {
          _this.pageLoading = false;
          console.log(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    calculateTotal(index) {
      let item = this.lineItem[index];

      if (item) {
        let rate = toNumber(item.rate);
        if (rate < 0) {
          rate = 0;
        }

        let quantity = toNumber(item.quantity);
        if (quantity <= 0) {
          quantity = 1;
        }
        const total = rate * quantity;
        this.lineItem[index].quantity = quantity;
        this.lineItem[index].rate = rate;
        this.lineItem[index].total = total;
      }

      /* if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      } */
    },
    validateCheckbox() {
      const len = filter(this.lineItem, { status: true }).length;
      const default_len = this.lineItem.length;
      if (default_len) {
        if (default_len == len) {
          this.allcheckbox = true;
        } else {
          this.allcheckbox = false;
        }
      } else {
        this.allcheckbox = false;
      }
    },
    checkSelected(line_item) {
      if (this.show_selected) {
        return line_item.status;
      }
      return true;
    },
    setPODate(param) {
      this.purchaseOrderCreate.date = param;
    },
    setExpectedDate(param) {
      this.purchaseOrderCreate.expected_delivery_date = param;
    },
    setDueDate(param) {
      this.purchaseOrderCreate.due_date = param;
    },
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "purchase-order/options",
          data: { project: _this.project, contractor: _this.contractor },
        })
        .then(({ data }) => {
          _this.projectList = data.projects;
          _this.quotationList = data.quotation;
          _this.paymentTermList = data.payment_term;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    async updateOrCreate(action) {
      try {
        const _this = this;

        const validateStatus = _this.$refs.purchaseOrderForm.validate();

        const formErrors = _this.validateForm(_this.$refs.purchaseOrderForm);
        if (formErrors.length) {
          for (let i = 0; i < formErrors.length; i++) {
            ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
          }
          return false;
        }

        let lineItem = _this.lineItem;

        if (_this.is_id_person == true) {
          lineItem = _this.lodash.filter(_this.lineItem, { status: true });
        }

        let validateLineItem = _this.lodash.compact(
          _this.lodash.map(lineItem, function (row) {
            return row.product;
          })
        );

        if (_this.lodash.isEmpty(validateLineItem)) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Select any one line item then try again.")
          );
          return false;
        }

        if (!validateStatus) {
          return false;
        }

        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object({
          action,
          quotation: _this.purchaseOrderCreate.quotation.id,
          //reference: _this.purchaseOrderCreate.reference,
          project: _this.purchaseOrderCreate.project,
          contractor: _this.contractor,
          supplier_reference: _this.purchaseOrderCreate.supplier_reference,
          date: _this.purchaseOrderCreate.date,
          expected_delivery_date:
            _this.purchaseOrderCreate.expected_delivery_date,
          due_date: _this.purchaseOrderCreate.due_date,
          payment_term: _this.purchaseOrderCreate.payment_term,
          admin_remark:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.admin_notes
              : null,
          attachments:
            _this.lodash.isEmpty(_this.notesAttachment) === false
              ? _this.notesAttachment.attachments
              : [],
          term_conditions: _this.termCondition,
          additional_remarks:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.description
              : null,
          discount_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountType
              : null,
          discount_value_type:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValueType
              : null,
          discount_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.discountValue
              : null,
          adjustment:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.adjustmentAmount
              : null,
          status: _this.purchaseOrderAction == "raise" ? 2 : 1,
          is_taxed:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.is_taxed
              : null,
          tax_value:
            _this.lodash.isEmpty(_this.lineItemCalculation) === false
              ? _this.lineItemCalculation.tax_value
              : null,
        });

        if (!_this.purchaseOrderCreated) {
          try {
            let purchaseOrder = await _this.$store.dispatch(PUT, {
              url: "purchase-order/" + _this.purchaseOrderId,
              data: formData,
            });

            _this.purchaseOrder = _this.lodash.toSafeInteger(
              purchaseOrder.data.id
            );

            _this.purchaseOrderCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.purchaseOrderCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order is not created. Please try again."
              )
            );
          }
        }

        if (!_this.lineItemCreated && _this.purchaseOrderId) {
          try {
            await _this.CreateLineItems({
              type: "purchase_order",
              parent: _this.purchaseOrderId,
              formData: lineItem,
            });

            _this.lineItemCreated = true;
          } catch (error) {
            _this.logError(error);
            _this.lineItemCreated = false;
            ErrorEventBus.$emit(
              "update:error",
              InitializeError(
                "Purchase Order Line Item(s) are not created. Please try again."
              )
            );
          }
        }

        _this.formLoading = false;

        if (_this.purchaseOrderCreated && _this.lineItemCreated) {
          _this.$router.push(
            _this.getDefaultRoute("purchase.order.detail", {
              params: {
                id: _this.purchaseOrder,
              },
            })
          );
        }
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error.toString()));
      }
    },
    getPurchaseOrder() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "purchase-order/" + _this.purchaseOrderId,
        })
        .then(({ data }) => {
          _this.purchaseOrderCreate.quotation =
            _this.lodash.isEmpty(data.quotation) === false
              ? data.quotation
              : null;
          _this.purchaseOrderCreate.barcode = data.barcode;
          _this.purchaseOrderCreate.reference = data.reference;
          _this.purchaseOrderCreate.supplier_reference =
            data.supplier_reference;
          _this.purchaseOrderCreate.show_cost = data.show_cost;
          _this.purchaseOrderCreate.date = data.date;
          _this.purchaseOrderCreate.expected_delivery_date =
            data.expected_delivery_date;
          _this.purchaseOrderCreate.due_date = data.due_date;
          _this.purchaseOrderCreate.payment_term =
            _this.lodash.isEmpty(data.payment_term) === false
              ? data.payment_term.id
              : null;
          _this.contractor = data.contractor.id;
          _this.projectDetails = data.project;
          _this.project = data.project.id;
          _this.contractorDetails = data.contractor;
          let result = [];
          for (let i = 0; i < data.line_items.length; i++) {
            result.push({
              id: data.line_items[i].id,
              package_id: data.line_items[i].package_id,
              group: data.line_items[i].group,
              original_product: data.line_items[i].original_product,
              group_primary: data.line_items[i].group_primary,
              product: data.line_items[i].original_product.name,
              barcode: data.line_items[i].original_product.barcode,
              product_id: data.line_items[i].product,
              serial_no: data.line_items[i].serial_no,
              eq_model: data.line_items[i].eq_model,
              location: data.line_items[i].location,
              product_type: data.line_items[i].original_product.product_type,
              has_warranty: data.line_items[i].has_warranty,
              warranty: data.line_items[i].warranty,
              description: data.line_items[i].description,
              company_cost: data.line_items[i].original_product.company_cost,
              charges: data.line_items[i].original_product.charges,
              rate: data.line_items[i].rate,
              quantity: data.line_items[i].quantity,
              uom: data.line_items[i].uom,
              total: data.line_items[i].total,
            });
          }

          console.log({ result });

          _this.dbLineItems = result;

          _this.lineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_amount: data.discount_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
            is_taxed: data.is_taxed && data.is_taxed == 1 ? true : false,
            tax_value: data.tax_value,
          });

          _this.updateLineItemCalculation = new Object({
            additional_remarks: data.additional_remarks,
            sub_total: data.sub_total,
            tax_amount: data.tax_amount,
            discount_amount: data.discount_amount,
            discount_value: data.discount_value,
            discount_type: data.discount_type,
            discount_value_type: data.discount_value_type,
            adjustment: data.adjustment,
            total: data.total,
            is_taxed: data.is_taxed,
            tax_value: data.tax_value,
          });

          _this.updateNotesAttachment = new Object({
            admin_remark: data.admin_remark,
            client_remark: null,
            notify_admin: 0,
            notify_customer: 0,
            notify_engineer: 0,
            documents: data.documents,
          });

          _this.updateTermCondition = new Object({
            term_conditions: data.term_conditions,
          });
          _this.getOptions();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  beforeDestroy() {
    /*LineItemEventBus.$off("update:line-item");
    LineItemCalculationEventBus.$off("update:line-item-calculation");
    NoteAttachmentEventBus.$off("update:notes-attachment");
    TermConditionEventBus.$off("update:term-condition");*/
  },
  mounted() {
    const _this = this;
    //_this.getOptions();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Purchase Order", route: "purchase.order" },
      { title: "Update" },
    ]);

    const user = currentUser();
    const role_name = ObjectPath.get(user, "role_name");
    if (this.lodash.toLower(role_name) == "admin") {
      this.is_admin = true;
    }
    if (this.lodash.toLower(role_name) == "id") {
      this.is_id_person = true;
    }

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      _this.notesAttachment = argument;
    });

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      _this.termCondition = argument;
    });

    LineItemEventBus.$on("update:line-item", (argument) => {
      _this.lineItem = argument;
    });

    LineItemCalculationEventBus.$on(
      "update:line-item-calculation",
      (argument) => {
        _this.lineItemCalculation = argument;
      }
    );
  },
  created() {
    const _this = this;

    _this.purchaseOrderId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.purchaseOrderAction = _this.$route.params.action;
    if (_this.purchaseOrderId > 0) {
      _this.getPurchaseOrder();
    }
  },
  computed: {
    defaultStartDate() {
      if (this.purchaseOrderCreate.start_date) {
        return this.purchaseOrderCreate.start_date;
      }
      return new Date().toISOString().substr(0, 10);
    },
    defaultDueDate() {
      if (this.purchaseOrderCreate.due_date) {
        return this.purchaseOrderCreate.due_date;
      }
      let today = new Date();
      today.setDate(today.getDate() + 30);
      return today.toISOString().substr(0, 10);
    },
  },
};
</script>
